
export default class HTMLCanvas
{
  constructor(class_name)
  {
    this.container = document.createElement('canvas');
    this.container.className = `canvas ${class_name}`;
  }

  append_to(parent)
  {
    parent.appendChild(this.container);
  }
  
  load()
  {
    console.error('canvas load');
  }

  play()
  {
    console.error('canvas play');
  }

  pause()
  {
    console.error('canvas pause');
  }

  stop()
  {
    console.error('canvas stop');
  }

  set_volume(volume)
  {
    
  }

  synchronize()
  {

  }

  is_image_showing()
  {
    return true;
  }

  clear_buffer()
  {
    console.error('canvas clear buffer');
  }
}
