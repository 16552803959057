import 'regenerator-runtime/runtime'
import {
  OPAQUE_ID,
  JANUS_PLUGIN_LIVE_STREAM,
  UPDATE_GAP,
  JANUS_PLUGIN_RTSP,
} from '/js/components/webrtc/constants.js';
import { Socket } from '/js/components/webrtc/sockets.js';
//import { getStreamEl } from '../misc/helpers.js';
//import { Stream } from '../web-components/stream.js';

export default class JanusClient {
  _socket;
  _feeds = [];
  _numberOfAttemptsToConnect = 0;
  _janusClients = new Map();
  _allStreams = [];
  _visibleStreamsId = new Set();
  _liveStreams = {};
  _rtspStreams = {};
  _hasStreamsWithNoPublisher = false;
  _updateTime;
  _token;
  _NODE_URL;
  _JANUS_URL;

  constructor(token, parsedToken) {
    this._NODE_URL = parsedToken.nodeUrl.replace(/http(s)?:\/\//gm, 'wss://');
    this._JANUS_URL = parsedToken.janusUrl.replace(/http(s)?:\/\//gm, 'wss://');
    this._STUN_URL = parsedToken.stunUrl;     //stunUrl: "stun:rtc-st.fender360.com:3478"

    this._socket = undefined;

    this.reconnect_janus = false;

    this.initiate_connect(token, parsedToken);
  }

  initiate_connect(token, parsedToken)
  {
    console.log(`Initiate 'Janus' socketIO connection`);
    this._socket = new Socket(this._NODE_URL, token, parsedToken, 'janus');
    this._initJanus();

    this._socket.onConnectMessage(() => {
      this._socket.emitSystemMessage({ type: 'getToken', data: {} }).then(this._onTokenResponse.bind(this))
    });

    this._socket.onSystemMessage(({ type, data }) => {
      if (type === 'updateLiveStreams') {
        this._updateStreams(data);
      }
    });
  }

  update_auth_token(token)
  {
    this._socket.update_auth_token(token);
  }

  // TODO: What is the correct method to destroy a Janus client? i.e all sessions and connections
  destroy_client()
  {
    console.log(`[destroy_client]`);

    // Destroy sessions
    for(var s in Janus.sessions) {
      if(Janus.sessions[s] !== null && Janus.sessions[s] !== undefined &&
          Janus.sessions[s].destroyOnUnload) {
          Janus.log("Destroying session " + s);
          Janus.sessions[s].destroy({asyncRequest: false, notifyDestroyed: false});
      }
    }

    // Disconnect and destroy socketio client
    this._socket.disconnect() 
  }

  set_connected_callback(callback_connected, callback_connected_scope) {
    this.callback_connected = callback_connected;
    this.callback_connected_scope = callback_connected_scope;
  }

  set_data_callback(callback_data, callback_data_scope) {
    this.callback_data = callback_data;
    this.callback_data_scope = callback_data_scope;
  }

  set_remove_track_callback(callback_remove_track, callback_remove_track_scope) {
    this.callback_remove_track = callback_remove_track;
    this.callback_remove_track_scope = callback_remove_track_scope;
  }

  set_remove_stream_callback(callback_remove_stream, callback_remove_stream_scope) {
    this.callback_remove_stream = callback_remove_stream;
    this.callback_remove_stream_scope = callback_remove_stream_scope;
  }

  set_rtsp_connected_callback(callback_connected, callback_connected_scope) {
    this.callback_rtsp_connected = callback_connected;
    this.callback_rtsp_connected_scope = callback_connected_scope;
  }
/*
  set_rtsp_data_callback(callback_data, callback_data_scope) {
    this.callback_data = callback_data;
    this.callback_data_scope = callback_data_scope;
  }
*/
  set_rtsp_remove_stream_callback(callback_remove_stream, callback_remove_stream_scope) {
    this.callback_rtsp_remove_stream = callback_remove_stream;
    this.callback_rtsp_remove_stream_scope = callback_remove_stream_scope;
  }

  //document.querySelector('.js_add-form').addEventListener('form-submit', ({ detail }) => {
  init_rtsp_stream(url) {
    console.log(`[init_rtsp_stream] Initialising RTSP Janus stream. url: ${url}`);

    this._socket
      //.emitStreamMessage({ type: 'createRTSPStream', data: { url: detail.rtsp } })
      .emitStreamMessage({ type: 'createRTSPStream', data: { url: url } })
      .then(streamData => {
        Janus.debug(streamData);

if (1)
{
        //this._joinToRTSPStream(item);
        //TODO:  TEMP This is to trigger a getLiveStreams request
        this._startSession();
} 
else
{
        //streamData.name = detail.rtsp;
        streamData.name = url;

        let streaming;
        /*
        streamEl.addEventListener('removed', () => {
          if (streaming) {
            streaming.send({ message: { request: 'stop' }});
            streaming.hangup();
          }
        })
        */
        let item = {
          streamData: streamData
        };

        // Original from test site
        console.log(`[init_rtsp_stream] _JANUS_URL: ${this._JANUS_URL}`);
        let janus = new Janus({
          server: this._JANUS_URL,
          token: this._token,
          success: () => {
            janus.attach({
              plugin: JANUS_PLUGIN_RTSP,
              opaqueId: OPAQUE_ID,
              success: (pluginHandle) => {
                streaming = pluginHandle;
                Janus.log(`Plugin attached! (${streaming.getPlugin()}, id="${streaming.getId()})`);
                streaming.send({ message: { request: 'watch', id: parseInt(streamData.id) } });
              },
              onmessage: (msg, jsep) => {
                Janus.debug(' ::: Got a message :::');
                Janus.debug(msg);
                if (jsep !== undefined && jsep !== null) {
                  Janus.debug('Handling SDP as well...');
                  Janus.debug(jsep);
                  streaming.createAnswer({
                    jsep,
                    media: { audioSend: false, videoSend: false, data: true },
                    success: (jsep) => {
                      Janus.debug('Got SDP!');
                      Janus.debug(jsep);
                      streaming.send({ message: { request: 'start' }, jsep: jsep });
                    },
                    error: (error) => {
                      Janus.error('WebRTC error:', error);
                    }
                  });
                }
              },
              onremotestream: (stream) => {
                Janus.debug(' ::: Got a remote stream :::');
                Janus.debug(stream);
                streamData.streamObj = stream
                //let streamEl = getStreamEl(streamData.id)

                //if (streamEl) {
                //  streamEl.attachStream(stream);
                //}

                // GG From original bodyworn onremotestream code
                //const streamItem = this._liveStreams[stream.id];

                //if (streamItem) {
                //  streamItem.streamObj = streamObj;
                //}

                //Janus.debug(`RTSP Remote feed #${remoteFeed.rfindex}, stream: `, stream);
                Janus.debug(`RTSP Remote feed, stream: `, stream);

                if (this.callback_rtsp_connected)
                {
                  this.callback_rtsp_connected(this.callback_rtsp_connected_scope, janus, item, stream);
                }
              }
            });
          },
          error: (error) => {
            Janus.error(error);
          }
        });
}        
      })
      .catch(error => Janus.error(error));

  }


  _onTokenResponse(data) {
    this._token = data.token;
    Janus.debug(`Got new token: ${this._token}`);

    this._startSession();
  }

  _prepareLiveStream(item, isRestart) {
    console.log(`[_prepareLiveStream]`);
    if (item.streamData.connectingData?.publisherId) {
      if (this._visibleStreamsId.size <= 10 || (isRestart && this._visibleStreamsId.has(item.id))) {
        item.visible = true;
        this._visibleStreamsId.add(item.id);
      } else {
        item.visible = false;
      }

      item.noVideo = false;
      item.name = item.streamer.name;
      item.janus = null;
      item.streamObj = null;

      this._liveStreams[item.id] = item;

      if (item.visible) {
        this._startWatchStream(item);
      }
    } else {
      this._hasStreamsWithNoPublisher = true;
    }

    return item;
  }

  _startSession(isRestart) {
    console.log(`[_startSession]`);
    this._socket
      .emitSystemMessage({ type: 'getLiveStreams', data: {} })
      .then(data => {
        console.log(`[_startSession] 'getLiveStreams' Ack`);
        data.forEach((item, i, arr) => {
          Janus.debug(item);
          item.streamData = null;
          item.noVideo = true;

          let is_rtsp = item.room ? false : true;
          let uuid = is_rtsp ? item.initiatorId : item.id;

          console.log(`[_startSession] is_rtsp: ${is_rtsp}, uuid: ${uuid}`);

          // This is for Live Streams (non RTSP)
          //if (item.startedAt) {
          if (!is_rtsp) {
            return this._socket.emitSystemMessage({ type: 'getLiveStreamInfo', data: { streamId: item.id } }).then(data => {
            //return this._socket.emitSystemMessage({ type: 'getLiveStreamInfo', data: { streamId: uuid } }).then(data => {
              console.log(`[_startSession] 'getLiveStreamInfo' Ack`);
              item.streamData = data;

              if (item.streamData.connectingData) {
                this._allStreams.push(item);

                this._prepareLiveStream(item, isRestart);
              }

              if (i === arr.length - 1) {
                this._updateTime = Date.now();
                setTimeout(() => {
                  this._forceUpdateStreams();
                }, UPDATE_GAP);
              }
            });
          } 
          else
          {
            // RTSP Streams
            //if (item.startedAt || item.createdAt) {
            //return this._socket.emitSystemMessage({ type: 'getLiveStreamInfo', data: { streamId: item.id } }).then(data => {

            this._allStreams.push(item);
            this._rtspStreams[uuid] = item;
            this._joinToRTSPStream(item);
          }
        });
      })
      .catch(() => {
        Janus.error("Server error: Can't get live streams");
      });
  }

  _initJanus() {
    console.log('_initJanus');
    Janus.init({
      debug: 'all',
      //debug: ['warn','error'],
      //debug: false,
      callback() {
        if (!Janus.isWebrtcSupported()) {
          Janus.error('No WebRTC support...');
        } else {
          Janus.debug('Janus initiated');
        }
      }
    });
  }

  async _startWatchStream(stream) {
    console.log(`[_startWatchStream]`);
    Janus.debug(stream);
    if (!stream.streamData.connectingData.publisherId) {
      Janus.error('No publisher content');
      return;
    }
    if (stream.janus) {
      Janus.debug('______________reattach stream______________');
    } else {
      stream.janus = await this.get(stream.streamData.host);
      Janus.debug(stream.janus);
      //GG Test reconnect after connection down
      console.log(`[_startWatchStream] reconnect_janus: ${this.reconnect_janus}`);
      if (this.reconnect_janus)
      {
        console.log(`[_startWatchStream] Reconnecting janus`);
        await stream.janus.reconnect({
          success: function() {
            console.log("[_startWatchStream] Session successfully reclaimed:", stream.janus.getSessionId());
          },
          error: function(err) {
            console.error("[_startWatchStream] Failed to reconnect:", err);
          }
        });
      }
      await this._joinToLiveStream(stream.janus, stream.streamData);
    }
  }

  async get(host) {
    return this._janusClients.get(host) ?? (await this._createJanus(host));
  }

  _createJanus(host) {
    Janus.debug('Create Janus');
    return new Promise((resolve, reject) => {
      let janus = new Janus({
        server: this._JANUS_URL,
        token: this._token,
        success: () => {
          Janus.debug('Janus connected');
          this._janusClients.set(host, janus);
          this._numberOfAttemptsToConnect = 0;
          resolve(janus);
        },
        error: error => {
          if (this._numberOfAttemptsToConnect < 2) {
            if (error === 'Unauthorized request (wrong or missing secret/token)') {
              console.log(`[janusClient] wrong or missing secret/token. Sending getToken`)
              this._socket.emitSystemMessage({ type: 'getToken', data: {} }).then(this._onTokenResponse.bind(this));
            }
            this._numberOfAttemptsToConnect++;
          }
          if (this._numberOfAttemptsToConnect === 2) {
            Janus.error("Network error: Can't connect to WebRTC server");
            this._numberOfAttemptsToConnect++;
          }

          setTimeout(() => {
            reject(error);
          }, 250);
        }
      }, this._STUN_URL);
    }).catch(Janus.error);
  }

  _joinToLiveStream(janus, stream) {
    console.log(`[_joinToLiveStream]`);
    if (!janus) {
      return;
    }

    let remoteFeed = null;
    janus.attach({
      plugin: JANUS_PLUGIN_LIVE_STREAM,
      opaqueId: OPAQUE_ID,
      success: pluginHandle => {
        remoteFeed = pluginHandle;
        remoteFeed.simulcastStarted = false;
        Janus.debug(`Plugin attached! (${remoteFeed.getPlugin()}, id="${remoteFeed.getId()})`);
        Janus.debug('  -- This is a subscriber');

        const subscribe = {
          request: 'join',
          room: Number(stream.room),
          ptype: 'listener',
          feed: stream.connectingData.publisherId,
          private_id: 111
        };
        remoteFeed.videoCodec = 'vp8';
        remoteFeed.send({ message: subscribe });
      },
      error: error => {
        if (this._numberOfAttemptsToConnect < 2) {
          this._socket.emitSystemMessage({ type: 'getToken', data: {} }).then(this._onTokenResponse.bind(this));
        } else {
          Janus.error('Error attaching plugin: ', error);
        }
      },
      onmessage: (msg, jsep) => {
        Janus.debug(`::: Got a message (subscriber) :::`, msg);
        const event = msg['videoroom'];
        Janus.debug(`Event: ${event}`);
        if (msg['error']) {
          Janus.error(msg['error']);
        } else if (event) {
          switch (event) {
            case 'attached':
              for (let i = 0; i < 5; i++) {
                if (!this._feeds[i]) {
                  this._feeds[i] = remoteFeed;
                  remoteFeed.rfindex = i;
                  break;
                }
              }
              remoteFeed.rfid = msg['id'];
              remoteFeed.rfdisplay = msg['display'];
              Janus.debug(`Successfully attached to feed: `, remoteFeed);
              break;
            case 'event':
              const substream = msg['substream'];
              const temporal = msg['temporal'];

              if ((substream !== null && substream !== undefined) || (temporal !== null && temporal !== undefined)) {
                if (!remoteFeed.simulcastStarted) {
                  remoteFeed.simulcastStarted = true;
                }
              }
              break;
          }
        }
        if (jsep) {
          Janus.debug(`Handling SDP as well: `, jsep);
          remoteFeed.createAnswer({
            jsep,
            media: { audioSend: false, videoSend: false, data: true },
            success(res) {
              remoteFeed.send({ message: { request: 'start', room: Number(stream.room) }, jsep: res });
            },
            error(error) {
              Janus.error(`WebRTC error: ${error.message}`);
            }
          });
        }
      },
      iceState: state => {
        Janus.debug(`ICE state of this WebRTC PeerConnection (feed #${remoteFeed.rfindex}) changed to ${state}`);
      },
      webrtcState: on => {
        Janus.debug(`Janus says this WebRTC PeerConnection (feed #${remoteFeed.rfindex}) is "${on ? 'up' : 'down'}" now`);
      },
      onlocalstream: () => {
        Janus.debug('Local stream in use');
      },
      onremotestream: streamObj => {
        const streamItem = this._liveStreams[stream.id];

        if (streamItem) {
          streamItem.streamObj = streamObj;
        }

        Janus.debug(`Remote feed #${remoteFeed.rfindex}, stream: `, streamObj);

        // This callback can get called when Remote Track is started or onended als, also other reasons that should be checked
        if (streamObj.getVideoTracks()[0])
        {
          if (this.callback_connected)
          {
            this.callback_connected(this.callback_connected_scope, janus, stream, streamObj);
          }
        }
        else
        {
          if (this.callback_remove_track)
          {
            this.callback_remove_track(this.callback_remove_track_scope, janus, stream, streamObj);
          }
        }
      },
      oncleanup: () => {
        Janus.debug(' ::: Got a cleanup notification (remote feed ' + 'id' + ') :::');
      },
      ondataopen: () => {
        Janus.debug('The DataChannel is available!');
      },
      ondata: data => {
        const streamEl = document.querySelector(`stream-item[stream-id="${stream.id}"]`);

        if (streamEl) {
          streamEl.setAttribute('telemetry', data);
        }

        if (this.callback_data)
        {
          this.callback_data(this.callback_data_scope, data, janus, stream);
        }
      }
    });
  }

  _updateStreams(res) {
    Janus.debug(res);
    const data = res.streams;
    this._allStreams = this._allStreams.filter(({ groups }) => !groups.includes(res.group));
    this._updateTime = Date.now();

    for (let key in this._liveStreams) {
      console.log(`[_updateStreams] live stream [${key}]`);
      console.log(`[_updateStreams] find ${data.find(dataItem => dataItem.id === this._liveStreams[key].id)}`)
      if (!data.find(dataItem => dataItem.id === this._liveStreams[key].id)) {
        if (this.callback_remove_stream) {
console.log(`[_updateStreams] callback_remove_stream`);
console.dir(this._allStreams);
console.dir(this._allStreams[key]);

          this.callback_remove_stream(this.callback_remove_stream_scope, this._liveStreams[key]);
        }

        this._visibleStreamsId.delete(this._liveStreams[key].id);
        delete this._liveStreams[key];
      }
    }

    // Check for removed RTSP streams
    // TODO: Not tested yet, this will need changes to  backend to return RTSP and LIVE streams
    for (let key in this._rtspStreams) {
      console.log(`[_updateStreams] rtsp stream [${key}]`);
      console.log(`[_updateStreams] find ${data.find(dataItem => dataItem.initiatorId === this._rtspStreams[key].initiatorId)}`)
      if (!data.find(dataItem => dataItem.initiatorId === this._rtspStreams[key].initiatorId)) {
        console.log(`[_updateStreams] Remove RTSP Stream.`);

        if (this.callback_rtsp_remove_stream) {
          console.log(`[_updateStreams] rtsp_callback_remove_stream`);
          this.callback_rtsp_remove_stream(this.callback_rtsp_remove_stream_scope, this._rtspStreams[key]);
        }

        //this._visibleStreamsId.delete(this._liveStreams[key].id);
        delete this._rtspStreams[key];

      }
    }

    setTimeout(() => {
      data.forEach(item => {
        item.streamData = null;
        item.noVideo = true;

        /*
        if (item.startedAt && !this._liveStreams[item.id]) {
          this._socket
            .emitSystemMessage({
              type: 'getLiveStreamInfo',
              data: { streamId: item.id }
            })
            .then(data => {
              console.log(`[_updateStreams] 'getLiveStreamInfo' Ack`);
              item.streamData = data;

              this._prepareLiveStream(item);

              this._allStreams.push(item);
            });
        }
        */
        /////////////////////////////////

        let is_rtsp = item.room ? false : true;
        let uuid = is_rtsp ? item.initiatorId : item.id;

        console.log(`[_updateStreams] is_rtsp: ${is_rtsp}, uuid: ${uuid}`);

        // This is for Live Streams (non RTSP)
        //if (item.startedAt) {
        if (!is_rtsp) {
          if (item.startedAt && !this._liveStreams[item.id]) {
            this._socket
              .emitSystemMessage({
                type: 'getLiveStreamInfo',
                data: { streamId: item.id }
              })
              .then(data => {
                console.log(`[_updateStreams] 'getLiveStreamInfo' Ack`);
                item.streamData = data;

                this._prepareLiveStream(item);

                this._allStreams.push(item);
              });
          }
        } 
        else
        {
          // RTSP Streams
          this._allStreams.push(item);
          this._rtspStreams[uuid] = item;
          this._joinToRTSPStream(item);
        }
      });
    }, 2500);
  }

  _forceUpdateStreams() {
    if (Date.now() - this._updateTime < UPDATE_GAP) {
      setTimeout(() => {
        this._forceUpdateStreams();
      }, Date.now() - this._updateTime + 100);
      return;
    }

    if (this._hasStreamsWithNoPublisher) {
      this._allStreams.forEach((item, i, arr) => {
        if (item.streamData.connectingData.publisherId) {
          return;
        }

        this._socket
          .emitSystemMessage({
            type: 'getLiveStreamInfo',
            data: { streamId: item.id }
          })
          .then(data => {
            console.log(`[_forceUpdateStreams] 'getLiveStreamInfo' Ack`);
            item.streamData = data;
            Janus.debug(item.streamData);

            this._prepareLiveStream(item);

            if (i === arr.length - 1) {
              this._hasStreamsWithNoPublisher = !!this._allStreams.find(item => !item.streamData.connectingData.publisherId);

              if (this._hasStreamsWithNoPublisher) {
                setTimeout(() => {
                  this._forceUpdateStreams();
                }, UPDATE_GAP);
              }

              this._updateTime = Date.now();
            }
          });
      });
    }
  }

  _joinToRTSPStream(streamData) {
    console.log(`[_joinToRTSPStream]`);

    Janus.debug(streamData);
        //streamData.name = detail.rtsp;
        //streamData.name = url;
        streamData.name = 'RTSP';

        //const streamEl = Stream.drawInto('.js_rtsp-container', streamData, true);
        //streamEl.classList.add('stream-container__item');

        /*
        if (detail.websocket) {
          new WebSocket(detail.websocket).onmessage = ({ data }) => streamEl.setAttribute('telemetry', data);
        }
        */
        
        let streaming;
        /*
        streamEl.addEventListener('removed', () => {
          if (streaming) {
            streaming.send({ message: { request: 'stop' }});
            streaming.hangup();
          }
        })
        */
        let item = {
          streamData: streamData
        };

        // Test using existing framework
        //this._startWatchStream(item);
        
        let _this = this;

        // Original from test site
        console.log(`[_joinToRTSPStream] about to create Janus`)
        console.log(`[_joinToRTSPStream] _JANUS_URL: ${this._JANUS_URL}`);
        let janus = new Janus({
          server: this._JANUS_URL,
          token: this._token,
          success: () => {
            janus.attach({
              plugin: JANUS_PLUGIN_RTSP,
              opaqueId: OPAQUE_ID,
              success: (pluginHandle) => {
                streaming = pluginHandle;
                Janus.log(`[_joinToRTSPStream] Plugin attached! (${streaming.getPlugin()}, id="${streaming.getId()})`);
                streaming.send({ message: { request: 'watch', id: parseInt(streamData.id) } });
              },
              onmessage: (msg, jsep) => {
                Janus.debug('[_joinToRTSPStream] ::: Got a message :::');
                Janus.debug(msg);
                Janus.debug(jsep);
                if (jsep !== undefined && jsep !== null) {
                  Janus.debug('[_joinToRTSPStream] Handling SDP as well...');
                  Janus.debug(jsep);
                  streaming.createAnswer({
                    jsep,
                    media: { audioSend: false, videoSend: false, data: true },
                    success: (jsep) => {
                      Janus.debug('Got SDP!');
                      Janus.debug(jsep);
                      streaming.send({ message: { request: 'start' }, jsep: jsep });
                    },
                    error: (error) => {
                      Janus.error('[_joinToRTSPStream] WebRTC error:', error);
                    }
                  });
                } else {
                  if (msg?.result?.status === 'stopped')
                  {
                    console.log(`[_joinToRTSPStream] RTSP Stream stopped`);
                    if (this.callback_rtsp_remove_stream) {
                      this.callback_rtsp_remove_stream(this.callback_rtsp_remove_stream_scope, streamData);
                    }
                  }
                }
              },
              onremotestream: (stream) => {
                Janus.debug('[_joinToRTSPStream] ::: Got a remote stream :::');
                Janus.debug(stream);
                streamData.streamObj = stream
                //let streamEl = getStreamEl(streamData.id)

                //if (streamEl) {
                //  streamEl.attachStream(stream);
                //}

                // GG From original bodyworn onremotestream code
                //const streamItem = this._liveStreams[stream.id];

                //if (streamItem) {
                //  streamItem.streamObj = streamObj;
                //}

                //Janus.debug(`RTSP Remote feed #${remoteFeed.rfindex}, stream: `, stream);
                Janus.debug(`[_joinToRTSPStream] RTSP Remote feed, stream: `, stream);

                if (this.callback_rtsp_connected)
                {
                  this.callback_rtsp_connected(this.callback_rtsp_connected_scope, janus, item, stream);
                }
              },
              oncleanup: () => {
                Janus.debug('[_joinToRTSPStream] ::: Got a cleanup notification (remote feed ' + 'id' + ') :::');
              },
              ondataopen: () => {
                Janus.debug('[_joinToRTSPStream] The DataChannel is available!');
              },
              ondata: data => {
                Janus.debug('[_joinToRTSPStream] ondata');
                /*
                const streamEl = document.querySelector(`stream-item[stream-id="${stream.id}"]`);

                if (streamEl) {
                  streamEl.setAttribute('telemetry', data);
                }

                if (this.callback_data)
                {
                  this.callback_data(this.callback_data_scope, data, janus, stream);
                }*/
              }
            });
          },
          error: (error) => {
            Janus.error(error);
          }
        });
  }
}
