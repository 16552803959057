import { ArrayUtilities } from 'ohzi-core';
import ApplicationView from './common/ApplicationView';
import MenuView from '/js/components/html_components/MenuView';
import PhysicalCameraManager from '/js/components/PhysicalCameraManager';
import MenuCameraManager from '/js/components/MenuCameraManager';
import RoleManager from '/js/components/RoleManager';
import JanusClient from '/js/components/webrtc/JanusClient';
import BodywornClient from '/js/components/webrtc/BodywornClient';

// This handles the websocket messages
export default class WebRtcView extends ApplicationView
{
  // constructor(app, host, type)
  constructor(app)
  {
    super('webrtc', $('.menu__webrtc'));
    // this.host = host;
    // this.type = type;
    this.app = app;

    this.janusClients = {};
    this.feeds = [];

    this.allStreams = [];
    this.hasStreamsWithNoPublisher = false;
    this.updateTime = null;
    this.updateGap = 10000;

    this.bodyworn_user_list = [];
    this.open_player_on_stream = undefined;

    this.token = null;
    this.streams = {};
    this.gotToken = false;
    this.numberAttempToConnect = 0;

    // this.box = document.querySelector('.stream-container');
    this.box = undefined;

    //this.socket = undefined;
  }

  start()
  {
    console.log('WebRtcView:start] V2');

    if (!RoleManager.logged_user.nodeUrl || !RoleManager.logged_user.stunUrl)
    {
      console.warn('Current logged user doesn\'t have valid stunUrl or nodeUrl to access WebRTC');
      return;
    }

    if (!RoleManager.keycloak.hasRealmRole(RoleManager.roles.CUST_BW_USER) && !RoleManager.keycloak.hasRealmRole(RoleManager.roles.CUST_BW_ADMIN))
    {
      console.warn('Current logged user doesn\'t have role CUST_BW_USER or CUST_BW_ADMIN. WebRTC disabled.');
      return;
    }
  
    this.janus_client = new JanusClient(RoleManager.bearer_token, RoleManager.logged_user);

    // Live Bodyworn device callbacks
    this.janus_client.set_connected_callback(this.__on_webrtc_stream_connected, this);
    this.janus_client.set_data_callback(this.__on_webrtc_stream_data, this);
    this.janus_client.set_remove_track_callback(this.__on_webrtc_track_removed, this);
    this.janus_client.set_remove_stream_callback(this.__on_webrtc_stream_removed, this);

    // RTSP callbacks
    //this.janus_client.set_rtsp_connected_callback(this.__on_rtsp_stream_connected, this);
    //this.janus_client.set_rtsp_remove_stream_callback(this.__on_rtsp_stream_removed, this);

    // Setup bodyworn socket client. This is used for getting status of connected devices and remote control
    if (RoleManager.logged_user.bodywornSocket && RoleManager.logged_user.bodywornSocket != '')
    {
      this.bodyworn_client = new BodywornClient(RoleManager.bearer_token, RoleManager.logged_user);
      this.bodyworn_client.set_user_connected_callback(this.__on_bodyworn_user_connected, this);
      this.bodyworn_client.set_user_disconnected_callback(this.__on_bodyworn_user_disconnected, this);
      this.bodyworn_client.set_user_list_callback(this.__on_bodyworn_user_list, this);
      this.bodyworn_client.set_bodyworn_location_callback(this.__on_bodyworn_location, this);
      this.bodyworn_client.set_generic_event_callback('disconnect', this.__on_bodyworn_server_disconnected, this);
    }
  }

  reconnect_janus()
  {
    console.log(`[reconnect_janus]`);
    this.janus_client.initiate_connect(RoleManager.bearer_token, RoleManager.logged_user);
  }

  reinitialise_janus_client()
  {
    console.log(`[reinitialise_janus_client]`); 
    // Firstly have to destroy current Janus client and all sessions
    this.janus_client.destroy_client();

    this.janus_client = new JanusClient(RoleManager.bearer_token, RoleManager.logged_user);

    // Live Bodyworn device callbacks
    this.janus_client.set_connected_callback(this.__on_webrtc_stream_connected, this);
    this.janus_client.set_data_callback(this.__on_webrtc_stream_data, this);
    this.janus_client.set_remove_track_callback(this.__on_webrtc_track_removed, this);
    this.janus_client.set_remove_stream_callback(this.__on_webrtc_stream_removed, this);
  }

  test_update_auth_janus(token = undefined)
  {
    token = token ? token : RoleManager.bearer_token;
    console.log(`[test_update_auth_janus]`);
    //this.janus_client.update_auth_token(RoleManager.bearer_token);
    this.janus_client.update_auth_token(token);
  }

  test_update_auth_bodyworn(token = undefined)
  {
    token = token ? token : RoleManager.bearer_token;
    console.log(`[test_reconnect_bodyworn]`);
    //this.bodyworn_client.update_auth_token(RoleManager.bearer_token);
    this.bodyworn_client.update_auth_token(token);
  }

  // Entry to allow test starting a RTSP video stream
  start_rtsp(url='rtsp://10.147.18.106/axis-media/media.amp')
  {
    this.janus_client.init_rtsp_stream(url);
  }

  restart_socket_connections()
  {
    this.janus_client.initiate_connect(RoleManager.bearer_token, RoleManager.logged_user);
    this.bodyworn_client.initiate_connect(RoleManager.bearer_token, RoleManager.logged_user);
  }

  add_camera(physical_camera)
  {
    console.log('[WebRtcView:add_camera]');
    let menu_camera;
    try
    {
      const {add_camera:add_camera_to_pagination} = this.app.menu_view.menu_cameras_with_pagination();
      
      add_camera_to_pagination(physical_camera);
      menu_camera = MenuCameraManager.add_camera(physical_camera);
      menu_camera.stream.webrtc_janus = physical_camera.stream.stream;
      menu_camera.stream.webrtc_stream = physical_camera.stream.webrtc_stream;
      menu_camera.set_streaming_state(true);

      this.app.menu_view.fill_menu(0);

      if (physical_camera.lens_type === 'flat')
      {
        this.hide_camera_fisheye_frame(physical_camera.name);
      }

    }
    catch(err)
    {
      const {remove_camera} = this.app.menu_view.menu_cameras_with_pagination();
      remove_camera(physical_camera.name);
      MenuCameraManager.remove_not_active_camera(physical_camera.name);
      this.add_camera(physical_camera);

      
      return;
    }
  

    // menu_camera.stream.webrtc_janus = physical_camera.stream.webrtc_janus;


    // console.dir(menu_camera);
    // if (menu_camera)
    // {
    //   if (this.app.menu_options_view.show_thumbnail_video)
    //   {
    //     menu_camera.start_streaming();
    //   }

    //   if (this.app.menu_options_view.show_thumbnail_image)
    //   {
    //     menu_camera.start_streaming(true);
    //   }
    //   if (physical_camera.lens_type === 'flat')
    //   {
    //     this.hide_camera_fisheye_frame(physical_camera.name);
    //   }
    // }
    return menu_camera;
  }

  show_camera_fisheye_frame(camera_name)
  {
    let camera_el = $(`.menu__cameras-camera[data-name='${camera_name}']`);

    $(camera_el).find('.menu__cameras-camera-image-img').removeClass('invisible');
  }

  hide_camera_fisheye_frame(camera_name)
  {
    // console.log(`[WebRtcView:hide_camera_fisheye_frame]`);
    let camera_el = $(`.menu__cameras-camera[data-name='${camera_name}']`);
    // console.dir(camera_el);

    $(camera_el).find('.menu__cameras-camera-image-img').addClass('invisible');
  }

  /*
  Handle setting up a physical camera from the Janus Stream. This is called when metadata is received to know what type of video it is, 
  else if no data arrives after a time use default values (currently disabled)
  WebRTC Menu and Main Camera use the same streaming source (axis cameras create new RTSP streams)
  */
  setup_janus_stream_to_camera(janus, webrtc_item, webrtc_stream, cam_data)
  {
    console.log(`[setup_janus_stream_to_camera]`);
    console.dir(webrtc_item);
    console.dir(webrtc_stream);
  
    let height = 0;
    let width = 0;

    let name        = this.parse_name(webrtc_item.streamer.name);
    let user_groups = '' + webrtc_item.groups;

    let camera      = PhysicalCameraManager.get_by_keycloak_id(webrtc_item.sub);

    // If cam_data doesn't exist (no data in webRTC stream) then load defaults. This isn't ideal as might or might not be a fisheye camera
    if (!cam_data)
    {
      console.log(`[setup_janus_stream_to_camera] Warning no data associated with this camera so using default values.`);
      cam_data = {};
      cam_data.lens_type   = "flat";
      cam_data.orientation  = "portrait_back";
      //cam_data.orientation  = "landscape_uvc";
      cam_data.fov          = 220.0;
      cam_data.roll         = 0.0;
      cam_data.pitch        = 0.0;
      cam_data.yaw          = 0.0;
      cam_data.cam_lat      = 0.0;
      cam_data.cam_lon      = 0.0;
      cam_data.cam_alt      = 50.0;
    }
    // Always update these values
    cam_data.cam_type           = 'phone';
    cam_data.name               = cam_data.name ? cam_data.name : name;
    cam_data.user_groups        = user_groups;
    cam_data.id                 = webrtc_item.streamer.id;

    // If lens_type doesn't exist assign it (support older versions of app)
    cam_data.lens_type          = cam_data.lens_type ? cam_data.lens_type : 'flat';
    
    if (cam_data.lens_type == 'fisheye')
    {
      // Set default data required for fisheye
      cam_data.map_zoom         = 50;         // Distance from sphere i.e the size in the view
    }
    else
    {
      cam_data.map_zoom         = 10;         // Distance from plane i.e the size in the view
    }
    
    cam_data.fov                = cam_data.fov ? cam_data.fov : 220;
    cam_data.marker_scale       = 20;
    cam_data.marker_opacity     = 1;
    
    // Check if we are live camera is the same lens type as the original defined camera (with recordings and is in DB, could be different type)
    if (camera)
    {
      if (cam_data.lens_type !== camera.lens_type)
      {
        // If lens type is different then change physical camera lens type
        console.log(`Live camera lens_type '${camera.lens_type}' is different to the database camera lens_type '${cam_data.lens_type}'. Need to redefine camera`);

        // Need to maintain some camera values i.e camera._id
        let orig_id = camera._id;
        let keycloak_id = camera.keycloak_id;
        let bodyworn_available = camera.bodyworn_available;
        let bodyworn_user_name = camera.bodyworn_user_name;

        // Replace Physical Camera with new camera with correct lens type
        let new_camera = PhysicalCameraManager.replace_camera(camera, cam_data, webrtc_stream)

        new_camera._id = orig_id;
        new_camera.keycloak_id = keycloak_id;
        new_camera.bodyworn_available = bodyworn_available;
        new_camera.bodyworn_user_name = bodyworn_user_name;

        camera = new_camera;
      }

      const track = webrtc_stream.getVideoTracks()[0];
      if (track)
      {
        height = track.getSettings().height;
        width = track.getSettings().width;
      }
      else
      {
        console.log(`[setup_janus_stream_to_camera] Video track not available. Wdith and Height will be 0. Should abort!?`)
        console.dir(webrtc_stream);
        console.dir(track);
        return;
      }

      // Redefine as we use this to detect if camera has a live janus webrtc stream
      camera.stream.webrtc_janus = janus;

      // If camera is already receiving video
      if (camera.stream.is_streaming)
      {
        // camera.map_marker.cesium_marker_plane.clear_material();
        if (camera.map_marker.cesium_marker_plane) {
          camera.map_marker.cesium_marker_plane.appearance.material.uniforms.image = undefined;
        }
        camera.map_marker.set_video_resolution(height, width);
        let videoElement = camera.stream.html_video.container;
        // console.dir(videoElement);
        camera.map_marker.set_image_url(camera.stream.html_video.container);
      }
      else
      {
        // Assign Janus MediaStream object to the camera stream
        camera.stream.stream = webrtc_stream;

        if (height)
        {
          camera.video_height = height;
          camera.video_width = width;
        }

        camera.stream.webrtc_janus = janus;
        camera.plane_resolution_set = false;
      }

      let menu_camera = MenuCameraManager.get_by_name(camera.name);

      if (menu_camera)
      {
        console.log('[setup_janus_stream_to_camera] Menu camera exists, update stream');
        menu_camera.stream.webrtc_janus = janus;
        menu_camera.stream.stream = webrtc_stream;

        // Re-associate menu camera with new Physical Camera
        menu_camera.physical_camera = camera;

        if (camera.lens_type === 'flat')
        {
          this.hide_camera_fisheye_frame(camera.name);
        }
        else
        {
          this.show_camera_fisheye_frame(camera.name);
        }
        menu_camera.start_streaming();
        menu_camera.set_streaming_state(true);
      }
      else
      {
        console.log('[setup_janus_stream_to_camera] Adding menu camera');
        this.add_camera(camera);
      }

      if (this.open_player_on_stream === camera.name)
      {
        // Automatically select camera
        this.app.menu_view.select_physical_camera(camera);
      }

      this.app.modal_window_view.hide();
      this.app.notification_manager.stop_sound_connecting();

      return camera;
    }

    console.log('[setup_janus_stream_to_camera] Adding new physical camera');

    let new_camera = PhysicalCameraManager.add_camera(cam_data, webrtc_stream);

    // new_camera.webrtc_stream = webrtc_stream;  // TODO: Only used for getting height and width in PhyscailCameraMobile. Can get it from camera.stream.stream

    if (height)
    {
      new_camera.video_height = height;
      new_camera.video_width = width;
    }

    new_camera.stream.webrtc_janus = janus;
    new_camera.plane_resolution_set = false;

    if (new_camera)
    {
      let menu_camera = this.add_camera(new_camera);

      if (menu_camera)
      {
        // menu_camera.stream.webrtc_janus = janus;
        // menu_camera.stream.webrtc_stream = webrtc_stream;
      }

      // This will stream to the main player which we don't want to nessacery do
      // new_camera.stream.start_streaming(this.app.player_view.webrtc_plane_view_play.bind(this.app.player_view, new_camera));

      // Show arrow and text only on map
      new_camera.show_map_marker(true);
    }

    return new_camera;
  }

  // Remove janus stream from camera (and menu camera)
  remove_janus_stream_from_camera(camera)
  {
    console.log(`[remove_janus_stream_from_camera] Removing janus stream for camera '${camera.name}'`);

    if (camera === PhysicalCameraManager.selected_camera)
    {
      this.app.player_container.close();
      camera.stream.stop();
    }
    else
    {
      //camera.stream.stop_streaming();
    }

    // Disable open player to stream start
    if (this.open_player_on_stream === camera.name)
    {
      this.open_player_on_stream = undefined;
    }

    this.app.modal_window_view.hide();
    this.app.notification_manager.stop_sound_connecting();  

    let menu_camera = MenuCameraManager.get_by_name(camera.name);

    if (menu_camera)
    {
      menu_camera.stream.is_streaming = false;;
      menu_camera.set_streaming_state(false);
      // Clear the video container src
      menu_camera.stream.html_video.stop();
      // Undefine the Janus streams in the menu camera as these aren't available
      menu_camera.stream.webrtc_janus = undefined;
      menu_camera.stream.stream = undefined;
    }

    //camera.hide_map_marker(false);
    if (camera.is_bodyworn_live())
    {
      camera.hide_map_marker(true);
    }
    else
    {
      camera.hide_map_marker(false);
    }
    // Change menu thumbnail name back to default camera name
    camera.display_name = undefined;
    let camera_name = $(`.menu__cameras-camera[data-name='${camera.name}']`).find('.menu__cameras-camera-title');
    camera_name.text(camera.name);

    camera.plane_resolution_set = false;
    if (camera._id)
    {
      // Camera exists from server database so keep, just undefine the stream so it is hidden on live view.
      camera.stream.webrtc_janus = undefined;
      camera.stream.stream = undefined;
    }
    else
    {
      PhysicalCameraManager.remove_camera(camera);
    }
  }

  // Send command to bodyworn app to start streaming via socketio
  remote_stream_start(camera, open_player_on_stream=false)
  {
    let user_name = camera.bodyworn_user_name;
    let id = camera.keycloak_id;
    this.bodyworn_client.start_streaming(user_name, id);

    let menu_camera = MenuCameraManager.get_by_name(camera.name);
    menu_camera?.set_streaming_state(true);

    this.app.modal_window_view.show('Video connecting...',  true);
    this.app.notification_manager.play_sound_connecting();

    this.open_player_on_stream = open_player_on_stream ? camera.name : undefined;
    console.log(`[remote_stream_start] open_player_on_stream:  ${this.open_player_on_stream}`);

    let _this = this;

    // Check if remote start worked, if not send stop then start command again.
    this.remote_start_check_timer = setTimeout(function()
    {
      console.log(`[remote_stream_start] Check if remote start worked.`);
      console.dir(camera.stream.webrtc_janus);
      if (!camera.stream.webrtc_janus)
      {
        console.log(`[remote_stream_start] Remote start failed. Send stop then try again... (CURRENTLY DISABLED)`);
        /*
        TODO: TEMP disable the second retry
        _this.bodyworn_client.stop_streaming(user_name, id);
        setTimeout(function()
        {
          console.log(`[remote_stream_start] Now sending remote start command.`);
          //let menu_camera = MenuCameraManager.get_by_name(camera.name);
          menu_camera?.set_streaming_state(true);
          _this.bodyworn_client.start_streaming(user_name, id);
        }, 1000);
        */
      }
    }, 16000);
  }

  // Send command to bodyworn app to stop streaming via socketio
  remote_stream_stop(camera)
  {
    let user_name = camera.bodyworn_user_name;
    let id = camera.keycloak_id;

    clearTimeout(this.remote_start_check_timer);

    if (this.open_player_on_stream === camera.name)
    {
      this.open_player_on_stream = undefined;
    }

    let menu_camera = MenuCameraManager.get_by_name(camera.name);
    menu_camera?.set_streaming_state(false);

    this.app.modal_window_view.hide();
    this.app.notification_manager.stop_sound_connecting();  

    this.bodyworn_client.stop_streaming(user_name, id);
  }

  // Send command to bodyworn app to stop switch front/back cameras via socketio
  remote_stream_camera_switch(camera)
  {
    let user_name = camera.bodyworn_user_name;
    let id = camera.keycloak_id;

    this.bodyworn_client.camera_switch(user_name, id);
  }

  /*
  Callback called when new stream is connected
  janus = Janus object
  webrtc_item = Janus stream data
  webrtc_stream = Standard MediaStream class
  */
  __on_webrtc_stream_connected(_this, janus, webrtc_item, webrtc_stream)
  {
    console.log(`[__on_webrtc_stream_connected] '${webrtc_item.streamer?.name}'`);
console.dir(janus);
console.dir(webrtc_item);
console.dir(webrtc_stream);

    if (!webrtc_item || !webrtc_item.streamer)
    {
      console.warn('[__on_webrtc_stream_connected] webrtc_item.streamer. Exiting');
      return;
    }

    webrtc_item.webrtc_stream = webrtc_stream;

    setTimeout(function()
    {
      if (!webrtc_item.data_received)
      {
        console.log(`[__on_webrtc_stream_connected] No data received after 8s`);
        // No data recieved from bodyworn app but load stream anyway with default settings (disabled)
        //_this.setup_janus_stream_to_camera(janus, webrtc_item, webrtc_stream, undefined);
      }
    }, 8000);

    // Setup stream locally when stream first connected (don't wait for metadata like we were previously doing)
    // There was a infrequent bug that occurred after a long time of inactivity (~8hrs) where the video stream wouldn't start correctly and all websockets/socketio connections were stalling
    // This a fix for that issue, but still not the best way to do it. We have to assume the stream attributes ie fisheye etc.
    if (!webrtc_item.data_received)
    {
      console.log(`[__on_webrtc_stream_connected] TEMPORARY start video stream without using metadata '${name}`);
      webrtc_item.data_received = true;
      _this.setup_janus_stream_to_camera(janus, webrtc_item, webrtc_item.webrtc_stream, undefined);
    }
  }

  /*
  Callback called when data available on the webRTC stream
  webrtc_item = Janus stream data
  */
  __on_webrtc_stream_data(_this, webrtc_data, janus, webrtc_item)
  {
    //console.log(`[__on_webrtc_stream_data]`);
    if (_this.app.menu_view.current_tab === _this.app.menu_view.tabs.LIVE) {
      let data = JSON.parse(webrtc_data);

      let name = _this.parse_name(data.name);

      let camera      = PhysicalCameraManager.get_by_keycloak_id(webrtc_item.sub);

      // If callsign (name) from Bodyworn app is different to camera name defined for user then adjust the camera name to user-callsign
      if (camera.name !== name)
      {
        if (!camera.original_name)
        {
          camera.original_name = camera.name;
        }
        let new_name = `${camera.original_name}-${name}`;
        camera.display_name = new_name;
        let camera_name = $(`.menu__cameras-camera[data-name='${camera.original_name}']`).find('.menu__cameras-camera-title');
        camera_name.text(new_name);
      }

      data.name = camera.name;
      data.keycloak_id = webrtc_item.sub;

      // If lens type is fishye and orientation field is “portrait_uvc” then orientation is UP else it is down
      if (data.lens_type == 'fisheye')
      {
        data.orientation = data.orientation.includes("portrait") ? 'up' : 'down';
        data.fov = parseFloat(data.fov);
      }

      if (!webrtc_item.data_received && webrtc_item.webrtc_stream) {
        console.log(`[__on_webrtc_stream_data] First connect of camera '${name}`);
        webrtc_item.data_received = true;

        _this.setup_janus_stream_to_camera(janus, webrtc_item, webrtc_item.webrtc_stream, data);
      }

      PhysicalCameraManager.update_camera(camera.name, data);
      MenuCameraManager.camera_updated(camera.name);
    }
  }

 __on_webrtc_track_removed(_this, janus, webrtc_item, webrtc_stream)
  {
    console.log(`[__on_webrtc_track_removed] '${webrtc_item?.streamer?.name}'`);

    let camera      = PhysicalCameraManager.get_by_keycloak_id(webrtc_item.sub);

    console.log(`[__on_webrtc_track_removed] camera.stream.webrtc_janus: ` + camera.stream.webrtc_janus);
    console.log(`[__on_webrtc_track_removed] camera.stream.stream: ` + camera.stream.stream);

    // Make sure stream had previously been started before removing else this can trigger when first remote starting stream after a long time of inactive
    if (camera && camera.stream?.stream)
    {
      // TODO: TEMP Disable this as we get false triggers when remote starting sometimes. Condition below will resolve but keep disabled until remote start stable
      _this.remove_janus_stream_from_camera(camera);
      // This will let us setup the janus stream again on next __on_webrtc_stream_connected()
      webrtc_item.data_received = false;
    }

    _this.app.menu_view.fill_menu(0);
  }

  // TODO: Why is this not getting bound to this correctly?
  // __on_webrtc_stream_removed(data, data2)
  __on_webrtc_stream_removed(_this, data)
  {
    console.log(`[__on_webrtc_stream_removed] removed menu camera '${data.name}'`);

    let name = _this.parse_name(data.name);
    
    let camera = PhysicalCameraManager.get_by_keycloak_id(data.streamData.sub);

    if (camera)
    {
      _this.remove_janus_stream_from_camera(camera);
    }

    _this.app.menu_view.fill_menu(0);
  }

  /*
  Callback called when new stream is connected
  janus = Janus object
  webrtc_item = Janus stream data
  webrtc_stream = Standard MediaStream class
  */
  __on_rtsp_stream_connected(_this, janus, webrtc_item, webrtc_stream)
  {
    console.log(`[__on_rtsp_stream_connected] '${webrtc_item.streamer?.name}'`);
console.dir(janus);
console.dir(webrtc_item);
console.dir(webrtc_stream);

    if (!webrtc_item || !webrtc_item.streamer)
    {
      //console.warn('[__on_rtsp_stream_connected] No webrtc_item.streamer. Exiting');
      //return;
    }

    webrtc_item.webrtc_stream = webrtc_stream;

    if (_this.app.menu_view.current_tab === _this.app.menu_view.tabs.LIVE) {
      //let data = JSON.parse(webrtc_data);

      //let name = _this.parse_name(data.name);

      let camera      = PhysicalCameraManager.get_by_keycloak_id(webrtc_item.sub);

      if (!camera)
      {
        // Add camera

      }
      // If callsign (name) from Bodyworn app is different to camera name defined for user then adjust the camera name to user-callsign
      /*if (camera.name !== name)
      {
        if (!camera.original_name)
        {
          camera.original_name = camera.name;
        }
        let new_name = `${camera.original_name}-${name}`;
        camera.display_name = new_name;
        let camera_name = $(`.menu__cameras-camera[data-name='${camera.original_name}']`).find('.menu__cameras-camera-title');
        camera_name.text(new_name);
      }*/

      
      //data.name = camera.name;
      //data.keycloak_id = webrtc_item.sub;

      // Setup default values for RTSP camera
      let data = {
        name: webrtc_item.streamData.id,
        keycloak_id: webrtc_item.streamData.initiatorId,
        lens_type: 'fisheye',
        orientation: 'portrait',
        fov: '180'
      }
      //webrtc_item.streamer.name = webrtc_item.id;
      webrtc_item.streamer = {
        name: webrtc_item.streamData.id
      };
      webrtc_item.groups = 'iqqgelj9';
      webrtc_item.sub = webrtc_item.streamData.initiatorId;

      // If lens type is fishye and orientation field is “portrait_uvc” then orientation is UP else it is down
      if (data.lens_type == 'fisheye')
      {
        data.orientation = data.orientation.includes("portrait") ? 'up' : 'down';
        data.fov = parseFloat(data.fov);
      }

      if (!webrtc_item.data_received) {
        console.log(`[__on_rtsp_stream_connected] First connect of camera '${name}`);
        webrtc_item.data_received = true;

        camera = _this.setup_janus_stream_to_camera(janus, webrtc_item, webrtc_item.webrtc_stream, data);
      }

      PhysicalCameraManager.update_camera(camera.name, data);
      MenuCameraManager.camera_updated(camera.name);
    }
  }

  __on_rtsp_stream_removed(_this, data)
  {
    console.log('[__on_rtsp_stream_removed] removed RTSP');
    console.dir(data);

    //let name = _this.parse_name(data.name);
    
    let camera = PhysicalCameraManager.get_by_keycloak_id(data.initiatorId);

    if (camera)
    {
      _this.remove_janus_stream_from_camera(camera);
    }

    _this.app.menu_view.fill_menu(0);
  }

  __on_bodyworn_user_connected(_this, data)
  {
    console.log(`[__on_bodyworn_user_connected]`);

    _this.bodyworn_user_list.push(data);

    let name = data.name;
    let keycloak_id = data.sub;
    console.log(`Do we already have '${name}' keycloak_id: ${keycloak_id}?`);
    //let camera = PhysicalCameraManager.get_by_name(name)
    let camera = PhysicalCameraManager.get_by_keycloak_id(keycloak_id)
    if (camera)
    {
      console.log(`Setting camera '${name}' to bodyworn available`);
      camera.bodyworn_available = true;
      camera.bodyworn_user_name = data.name;
      _this.app.menu_view.fill_menu(0);
    }
  }

  __on_bodyworn_user_disconnected(_this, data)
  {
    console.log(`[__on_bodyworn_user_disconnected]`);

    let keycloak_id = data.sub;
    console.log(`Do we already have camera with keycloak_id: ${keycloak_id}?`);
    let camera = PhysicalCameraManager.get_by_keycloak_id(keycloak_id)
    if (camera)
    {
      console.log(`Set camera '${camera.name}' to unavailable. keycloak_id: ${keycloak_id}`);
      camera.bodyworn_available = false;
      camera.hide_map_marker(false);
      camera.hide_map_marker_point();

      _this.remove_janus_stream_from_camera(camera);
    }
    ArrayUtilities.remove_elem(_this.bodyworn_user_list, data);
  }

  __on_bodyworn_user_list(_this, data)
  {
    console.log(`[__on_bodyworn_user_list]`);

    _this.bodyworn_user_list = data;

    for (let i = 0; i < _this.bodyworn_user_list.length; i++)
    {
      let name = _this.bodyworn_user_list[i].name;
      let keycloak_id = _this.bodyworn_user_list[i].sub;
      console.log(`Do we already have '${name}' keycloak_id: ${keycloak_id}?`);
      //let camera = PhysicalCameraManager.get_by_name(name)
      let camera = PhysicalCameraManager.get_by_keycloak_id(keycloak_id)
      if (camera)
      {
        console.log(`Setting camera [${i}] '${name}' to bodyworn available`);
        camera.bodyworn_available = true;
        camera.bodyworn_user_name = name;
      }
    }
    _this.app.menu_view.fill_menu(0);
  }

  __on_bodyworn_location(_this, data)
  {
    //console.log(`[__on_bodyworn_location]`);
    //console.dir(data);

    let name = data.name;
    let keycloak_id = data.sub;
    //console.log(`Do we already have '${name}' keycloak_id: ${keycloak_id}?`);
    let camera = PhysicalCameraManager.get_by_keycloak_id(keycloak_id)
    if (camera)
    {
      //console.log(`Updating camera '${name}' location`);
      //camera.bodyworn_available = true;
      camera.bodyworn_user_name = name;

      let cam_data = {
        cam_lat: data.lat,
        cam_lon:  data.lon,
        cam_alt: data.alt,
        motion: data.motion,
        battery: data.battery,
        // Use default RPY values
        roll:  0,
        pitch: 0,
        yaw: 0
      }
      camera.bodyworn_last_live_update = new Date().getTime();
      // Only show green dot and update location if currently not streaming video and in Live mode only
      if (!camera.is_streaming() && _this.app.menu_view.current_tab === _this.app.menu_view.tabs.LIVE)
      {
        PhysicalCameraManager.update_camera(camera.name, cam_data);
        MenuCameraManager.camera_updated(camera.name);
        camera.show_map_marker_point();
      }

      // Update battery level
      let menu_camera = MenuCameraManager.get_by_name(camera.name);
      if (menu_camera && data.battery)
      {
        menu_camera.set_battery_value(data.battery);
      }
    }
  }

  __on_bodyworn_server_disconnected(_this, data)
  {
    console.log(`[__on_bodyworn_server_disconnected]`);

    // When bodyworn command server is discconected set all bodyworn cameras to unavailable
    PhysicalCameraManager.cameras.forEach(function (camera) {
      if (camera.bodyworn === 'yes')
      {
        camera.bodyworn_available = false;
        camera.hide_map_marker(false);
        camera.hide_map_marker_point();

        _this.remove_janus_stream_from_camera(camera);
      }
    });
  }

  // Handle devices with an email as the name
  parse_name(name)
  {
    let parsed_name = name;
    let n = name.indexOf("@");
    if (n > 0)
    {
      parsed_name = name.substring(0,n);
    }
    parsed_name = parsed_name.replace(".", "");

    return parsed_name;
  }
}
